@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-family: "Public Sans"; */
}

html {
  scroll-behavior: smooth;
}

:root {
  --primary: #ff7129;
  --secondary: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.link {
  text-decoration: none;
  color: inherit;
}
